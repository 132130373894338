#official {
  padding: 0;
  margin: 0;
}
.app {
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
}

.mount-container {
  background: "lightgreen";
  width: 100%;
  height: 100%;
}

.indicator-index {
  position: fixed;
  top: 20px;
  right: 0px;
  width: 200px;
  height: 20px;

  background: green;
}

.return-btn {
  /* width: 58px; */
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  background: #fe4545;
  opacity: 85%;
  color: white;
  position: fixed;
  top: 5%;
  border-radius: 0 20px 20px 0;
  padding: 0 5px;
  display: none;
}

.menu-group {
  position: fixed;
  right: 3em;
  bottom: 8em;
}

.ai-btn {
  padding: 0.6em;
  border-radius: 24em;
  width: 2em;
  height: 2em;
  background-image: url(/public/assets/svgs/ai.svg);
  background-repeat: no-repeat;
  background-size: 2em 2em;
  background-color: #fe4545;
  background-position: center;
  cursor: pointer;
}

.download-btn {
  margin-top: 0.2em;
  padding: 0.6em;
  border-radius: 24em;
  width: 2em;
  height: 2em;
  background-image: url(/public/assets/svgs/download.svg);
  background-repeat: no-repeat;
  background-size: 2em 2em;
  background-color: #000000;
  background-position: center;
  cursor: pointer;
}

#officail {
  display: none;
}