.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.Section-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.Section-title > svg {
  margin-right: 6px;
}

.Active-section-title {
  color: blue;
}
.App-header {
  background-color: #000000;
  height: 63px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-Container {
  flex: 1;
  width: 100vw;
  overflow-y: auto;
}
.App-link {
  color: #61dafb;
}

